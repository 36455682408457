















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient,
} from '@/modules/scheduled-reports/interfaces';
import loop24 from '@/modules/common/filters/loop-24.filter';
import ClusterService, { ClusterServiceS } from '../cluster.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(ClusterServiceS) public clusterService!: ClusterService;

    readonly dataType = DATA_TYPE.RANKING_CLUSTER;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    get filters(): IFilterItem[] {
        return [] as IFilterItem[];
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
